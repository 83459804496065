import React from 'react';
import {Button, Card, Circle, Flex, Heading, HStack, Icon, Spacer, Text, VStack, Image} from '@chakra-ui/react';
import {FaFileInvoice} from "react-icons/fa";
import {ArrowRightIcon} from '@chakra-ui/icons';
import {useAppDispatch} from "../../store/store";
import {selectInvoice} from "../../actions/invoices.actions";
import {Invoice} from "../../types/invoice.types";
import {formatCurrency} from '../../utils/currencyFormat';

interface InvoiceCardProps {
    invoice: Invoice
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({invoice}) => {
    const dispatch = useAppDispatch()
    const invoiceValidations = invoice.validations;


    const handleButtonClick = (invoice: Invoice) => {
        dispatch(selectInvoice(invoice))
    };

    // @ts-ignore
    return (
        <Card minHeight={"105px"} p={"10px"} overflow='hidden' variant='elevated' width={"100%"}>
            <Flex alignItems={"center"} justify={'space-between'} height={"100%"}>
                <Flex alignItems={"center"} className='invoce-card-container' marginRight={'5px'}>
                    <Circle size="65px" bg={"blue.400"} mr="4" opacity={"90%"}>
                        <Icon as={FaFileInvoice} boxSize={"26px"} color={"white"}/>
                    </Circle>
                    <VStack align="start" width={'auto'} spacing={0}>
                        <Heading size="md" mb={"3px"}>{invoice.invoice_id}</Heading>
                        <Text fontSize="sm">{invoice.issue_date ? invoice.issue_date : "No existe fecha"}</Text>
                        <Text fontSize="sm">{invoice.sender_party_name.length > 50 ? `${invoice.sender_party_name.slice(0, 50)}...` :  invoice.sender_party_name}</Text>
                        {/*<Text fontSize="sm">{formatCurrency(invoice?.total_amount_to_pay)} COP</Text>*/}
                    </VStack>
                </Flex>
                <Flex height={'100%'} alignItems={"center"}>
                    <VStack mr={4}>
                        <Image src="/intuipos_icon.png" alt="validate" boxSize="20px" opacity={ invoiceValidations?.purchase_order ? "100%" : "15%"} />
                        <Image src="/taxxa_icon.png" alt="validate" boxSize="20px" opacity={ invoiceValidations?.taxxa ? "100%" : "15%"}/>
                        {/*<Image src="/dian_icon.png" alt="validate" width={"35px"} opacity={ invoiceValidations?.dian ? "100%" : "15%"} />*/}
                    </VStack>
                <Button display={'block'} size="sm" color={"blue.500"} mt={"5px"} width={"100px"} height={"100%"}
                        onClick={() => {
                            handleButtonClick(invoice)
                        }}>
                    <HStack alignItems={"center"} justify={'center'}>
                        <Text fontSize={"md"}>Ver</Text>
                        <Icon as={ArrowRightIcon} boxSize={"12px"}/>
                    </HStack>
                </Button>
                </Flex>
            </Flex>
        </Card>
    );
};

export default InvoiceCard;
